import Vue from 'vue'
import Vuex from 'vuex'

import about from './modules/about'
import admin from './modules/admin'
import slide  from './modules/slide'
import banner  from './modules/banner'
import main from './modules/main'
import blog from './modules/blog'
import tag from './modules/tag'
import colaboradores from './modules/colaboradores';
import usuarios from './modules/usuarios'
import blog_category from './modules/blog_category'
import category from './modules/category'
import suscripciones from './modules/suscripciones'
import colors from './modules/colors'
import profile from './modules/profile'
import logo from './modules/logo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    about,
    admin: admin,
    slide: slide,
    banner: banner,
    main:main,
    usuarios:usuarios,
    blog:blog,
    tag:tag,
    blog_category:blog_category,
    colaboradores: colaboradores,
    category:category,
    suscripciones:suscripciones,
    profile:profile,
    colors:colors,
    logo:logo,
  }
})
