import axios from "axios";


const cPost = 'NFY_e9d/okm-r76'
const cGet = 'c15_BjM/UQ7-CoE'
const cPut = 'vbl_3AY/Kxn-8FR'
const cDel = 'zZN_0y0/OBF-gaF'

export default {

  login(user, headers){
    return axios.post(`/${cPost}/login`, user, headers);
  },

  getInfoUser(data, headers){
    return axios.post(`/${cPost}/info_usr`, data, {headers});
  },


  getStatusImage(payload, headers){
    return axios.get(`/${cGet}/${payload.option}-img/${payload.image}`, {headers});
  },

  getAllInfo(option, headers){
    return axios.get(`/${cGet}/${option}`, {headers});
  },
  getInfoById(payload, headers){
    return axios.get(`/${cGet}/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/${cGet}/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${cPut}/${payload.option}/upload/image/${payload.id}`, payload.item, {headers} );
  },

  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${cDel}/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${cPut}/${payload.option}/update`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${cPost}/${payload.option}/create`, payload.item, {headers});
  },


  resendCode(user, headers){
    return axios.post(`/${cPost}/reenviar-codigo`, user, headers);
  },

  addSuscriptor(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${cPost}/subs/user/suscribe`, payload, {headers});
  },

  

};