'use strict'

import api from '@/api/api';
import userService from '../../services/user.service';


const SET_TOKEN     = 'SET_TOKEN',
      SET_IDENTITY  = 'SET_IDENTITY',
      DOWNLOADING = "DOWNLOADING",
      DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";

export default {
    namespaced: true,
    state:{
        token: '',
        identity: { },
        dText: "Descargar Reporte.CSV",
    dflag: false,
    },
    getters: {
        getToken(state){
            return state.token;
        },
        getIdentity(state){
            return state.identity;
        },
        downloadFlag(state) {
            return state.dflag;
          },
          downloadText(state) {
            return state.dText;
          },
    },
    mutations: {
        [SET_TOKEN](state, payload){
            state.token = payload;
        },
        [SET_IDENTITY](state, payload){
            state.identity = payload;
        },
        [DOWNLOADING](state, change) {
            state.dflag = false;
            state.dText = change;
          },
          [DOWNLOAD_SUCCESS](state, change) {
            state.dflag = true;
            state.dText = change;
          },
    },
    actions:{
        async login({commit}, payload) {
            let email = payload.email,
                password = payload.password;

            const user = { email, password };
            try {
                let headers = { "Content-Type": "application/x-www-form-urlencoded" };
                let response = await api.login(user, headers);
                
                localStorage.clear(); //limpiar el localstorage

                if(response.data.status =='success'){
                    commit(SET_TOKEN, response.data.result);
                    localStorage.setItem("tkn", response.data.result);
                }
              
                return response.data;
            } catch (error) {
                return {
                    status:'error',
                    message:'Ha ocurrido un error al intentar iniciar sesión.',
                    result:''
                }
            }
        },

        async getData({commit}){
            try {
                
                let response = await userService.getData()

                if(response.status =='success'){ //el token si fue valido y se obtuvo el usuario.
                    commit(SET_IDENTITY, response.result);
                  
                }
              
                return response;
                
               
            } catch (error) {
                return {
                    status:'error',
                    message:'Ha ocurrido un error.',
                    result:''
                }
            }
        },

     
       
        
        logout({commit}) {          
            localStorage.clear();           
            commit(SET_IDENTITY, null);
            commit(SET_TOKEN, null);            
        },
        
     
   
    }
}