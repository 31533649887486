'use strict'

exports.url = () =>{
     return 'https://revistafoodie.com/api/MJ7_Tbl/JCu-UN3';
     //return 'http://localhost:3332/api/MJ7_Tbl/JCu-UN3';
}

exports.createURL = (function() {
     let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç", 
         to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
         mapping = {};
    
     for(let i = 0, j = from.length; i < j; i++ )
         mapping[ from.charAt( i ) ] = to.charAt( i );
    
     return function( str ) {
         let ret = [];
         for( let i = 0, j = str.length; i < j; i++ ) {
             let c = str.charAt( i );
             if( mapping.hasOwnProperty( str.charAt( i ) ) )
                 ret.push( mapping[ c ] );
             else
                 ret.push( c );
         }      
         return ret.join( '' ).replace( /[^-A-Za-z0-9]+/g, '-' );
     }
    
 })()