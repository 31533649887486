import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'vue-dayjs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCarousel from 'vue-carousel'
import VueInstagram from 'vue-instagram'
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

// import style (<= Swiper 5.x)


let urlpath = require('./global/url')

Vue.use(dayjs)

Vue.use(VueInstagram)



Vue.use(VueCarousel);

Vue.use(VueAxios, axios)

axios.defaults.baseURL =urlpath.url();

Vue.config.productionTip = false

new Vue({
  router,
  store, 
  components: {
    VueInstagram
  },
  render: h => h(App),
}).$mount('#app')
