<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import { mapActions } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
      colores: ''
    }
  },

  async created() {
    await this.getAllInfoClr('profile')

    this.colores = this.profiles
    this.actualizarColores()
  },
  watch: {
    profiles() {

      this.actualizarColores()

    }
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions('colors', ['getAllInfoClr']),
    ...mapActions('profile', ['getAllInfoPrf']),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];



      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {

          if (i == 0) {
            document.documentElement.style.setProperty('--color-1', arr[i].code);
          } else if (i == 1) {
            document.documentElement.style.setProperty('--color-2', arr[i].code);
          } else if (i == 2) {
            document.documentElement.style.setProperty('--color-3', arr[i].code);
          } else if (i == 3) {
            document.documentElement.style.setProperty('--color-4', arr[i].code);
          } else if (i == 4) {
            document.documentElement.style.setProperty('--color-5', arr[i].code);
          } else if (i == 5) {
            document.documentElement.style.setProperty('--color-6', arr[i].code);
          } else if (i == 6) {
            document.documentElement.style.setProperty('--color-7', arr[i].code);
          }
        }
      }
    }
  },
}

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
  --color-1: #777777;
  --color-2: #b5b5b5;
  --color-3: #48deff;
  --color-4: #8d8d8d;
  --color-5: #ffffff;
  --color-6: #3f3f3f;
  --color-7: #f24e4e;
  --color-8: #919191;
  --color-9: #f54c49;
  --color-10: #3383b1;
  --color-11: #215675;


  --Sans: 'Source Sans Pro', sans-serif;
  --RedHat: 'Red Hat Display', sans-serif;
  --Poppins: "Poppins", sans-serif;
}

input:focus-visible {
  outline: none !important;
}

.VueCarousel-navigation-button {
  display: none;
}

input,
textarea,
select {
  outline: none;
}

.buscador input:focus-visible {
  outline: none;
}


.div-user {
  width: 20%;
}

img.logo {
  width: 100%;
}

body {
  margin: 0;
  font-size: 3.382vw;
  font-weight: 400;
  line-height: 5.314vw;
}

#app {
  margin: 0vw;
  width: 100%;
  min-height: 56.25VW;
  font-family: var(--Poppins);
  /* font-family: var(--Sans); */
}

div#body {
  padding-top: 17vw;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
  background-color: #F4F4F4;
  /** border-radius: 1.0416666666666665VW;  */
}

body::-webkit-scrollbar-button:increment,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  /** border-radius: 1.0416666666666665VW;  */
  border: 0.10416666666666667vw solid #000;
}

body::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

@media (min-width: 1024px) {
  div#body {
    padding-top: 0;
  }
}

/** SOLO ESTILOS GLOBALES / reutilizables) */
.div-logo {
  border: 1px solid var(--color-3);
  width: 60%;
  background: var(--color-2);
  padding: 1vw;
}

img.logo {
  width: 100%;
}

p.upd-logo {
  color: var(--color-2);
  font-weight: 600;
  font-size: 1vw;
  text-transform: uppercase;
}

/* Estilos unicos de la vista en su propia vista. */
/*** validacione */

.val_no {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  border-top: 0.2vw solid gainsboro;
  border-bottom: 0.2vw solid gainsboro;
  padding: 9vw 0;
  font-family: var(--RedHat);
  font-weight: 700;
  color: rgb(130, 130, 130);
}

/***fin validaciones */
/***slides */
.slide-item {
  width: 100%;
  height: 102.961vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}

.slide-item.mobile {
  display: flex;
}

.slide-item.pc {
  display: none;
}

.slide-cont {
  padding: 0 0 18vw 12vw;
}

.slide-cont a {
  padding: 4.106vw 6.280vw;
  border: 0.6vw solid white;
  font-weight: 700;
  color: white;
  font-size: 4.106vw;
  text-transform: uppercase;
  transition: .5s;
}

.slide-cont a:hover {
  background-color: white;
  color: black;
}

/**fin slides */
/***blog items */
.items-c {
  padding-top: 4.831vw;
}

.post-c {
  width: 85.507vw;
  margin: auto auto 7.246vw;
  border: 1px solid gainsboro;
}

.post-c img.post-img {
  width: 85.507vw;
  height: 47.500vw;
  object-fit: cover;
}

.post-c .info-c {
  padding: 4.831vw 7.246vw 17.246vw;
}

.post-c .info-c p {
  margin: 0;
  font-size: 3.140vw;
  color: #999;
  line-height: normal;
}

.post-c .info-c p.title {
  margin: 0;
  font-size: 4.348vw;
  font-weight: 700;
  color: rgb(0, 0, 0);
  transition: .5s;
}

.post-c:hover .info-c p.title {
  color: #999;
}

.post-c .info-c p.date {
  padding-top: 2vw;
}

.post-c a.btn {
  margin: 0 auto 4.831vw 7.246vw;
  display: flex;
  width: fit-content;
}

.post-c a.btn p {
  padding: 2.174vw 4.831vw;
  border: 0.242vw solid black;
  margin: 0;
  font-family: var(--RedHat);
  font-weight: 700;
  color: black;
  transition: .5s;
}

.post-c a.btn p:hover {
  background: black;
  color: white;
}

/***fin blog items */
/*****admin */
.w100 {
  width: 100%;
}

.dflx {
  display: flex;
}

.jcsb {
  justify-content: space-between;
}

.dpl {
  display: none;
}

.aic {
  align-items: center;
}

.cp {
  cursor: pointer;
}

.fdc {
  flex-direction: column;
}

a {
  text-decoration-color: transparent;
}

hr.hrhd {
  width: 70.72916666666667vw;
  margin-left: 0px;
  border-bottom: 0.2px solid #b1b1b1;
  margin-top: 1.6145833333333335vw;
  margin-bottom: 2.3166666666666665vw;
}

.carg_btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7vw;
}
.mtp2{
  margin-top:1vw !important;
}
.ql-align-right{
  text-align: right !important;
}

.ql-align-left{
  text-align: left !important;
}

.ql-align-center{
  text-align: center !important;
}
.ql-align-justify{
  text-align: justify !important;
}

.img_chica{
  width: 25% !important;
}
.img_mediana{
  width: 50% !important;
}
.img_grande{
  width: 100% !important;
}

.options_tam{
  display: flex;
  margin-top: 1vw;
}
.options_tam p{
  display: flex;
  align-items: center;
  margin: 0vw;
  font-size: .8vw;
  cursor: pointer;
  width: 7vw;
  margin-right: .4vw;
  justify-content: center;
  height: 2vw;
 border: 2px solid #919191;
  transition: all 300ms;
  border-radius: 5vw;
}

.option_tam_act{
  border: 2px solid var(--color-1) !important;
  background-color: var(--color-1) !important;
  color: white;
}

.carg_btns p:first-child {
  margin-right: 1vw;
  padding-left: .5vw;
}
.mtp{
  margin-top: 1vw !important;
}
.carg_btns p {
  background-color: #4196f7;
  color: #Fff;
  border-radius: 1.5vw;
  height: 2vw;
  margin: 0vw;
  letter-spacing: 3px;
  font-family: var(--DMSans);
  font-size: .7vw;
  min-width: 14vw;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.carg_btns p img {
  width: 2.5vw;
}

.brdr{
  height: 2px;
  background-color: #ccc;
  width: 70.7vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
p.subttl{
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: 1vw;
}
/** MENSAJES DE EXITO/ERROR */
.status_messages {
  margin-top: 1vw;
}

.msg p {
  color: var(--color-5);
  width: 20vw;
  padding: 0.5vw;
  font-family: var(--Poppins);
  font-size: 0.7333333333333334vw;
  font-style: normal;
}

.msg_success {
  background: var(--color-2);
  width: 20vw;
}

.msg_error p {
  background: var(--color-7);
  width: 20vw;
}

.swiper-wrapper {
  display: flex;
  width: 86.4vw !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

/** FIN MENSAJES DE EXITO/ERROR */

/** NAVIGATE BUTTONS */
#navigate-buttons {
  margin-left: 1.3vw;
  margin-top: 0.1vw;
}

#navigate-buttons button {
  border: 0px;
  background: var(--color-5);
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
  transition: all 300ms;
  color: var(--color-5);
  font-size: 1vw;
  margin-top: 0.7vw;
}

/*#navigate-buttons button:hover{
       
        background: var(--color-2);
       
        
    }*/
p.num-pages {
  color: #797979;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding-top: 0.2vw;
  font-size: 0.7vw;
}

p.cp {
  cursor: pointer;
}

/** FIN NAVIGATE BUTTONS */

/** MODAL delete*/

.modal_view {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;

  z-index: 9000;
}

#modal_delete {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}

#modal_delete .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_delete h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0vw;
}

#modal_delete h3 span {
  font-weight: 700;
}

#modal_delete img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.9270833333333335vw;
  height: 1.8229166666666667vw;
}

.modal_del_btns {
  justify-content: center;
}

.modal_del_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

/** fin modal delete */

/** modal add */
#modal_add {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}

#modal_add .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_add h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
  width: 10vw;
}

#modal_add img {
  padding-top: 0.54vw;
  padding-left: 3vw;
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.modal_add_btns {
  justify-content: center;
}

.modal_add_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

/** fin  modal add */

/** modal edit */
#modal_edit {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}

#modal_edit .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_edit h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  width: 13vw;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
}

#modal_edit img {
  padding-top: 0.54vw;

  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.img-item img {
  max-width: 15vw;
}

.modal_edit_btns {
  justify-content: center;
}

.modal_edit_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

/** fin  modal add */

/** modal logout */

#modal_logout {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}

#modal_logout .body {
  background: var(--color-2);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_logout h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0.5vw;
}

#modal_logout h3 span {
  font-weight: 700;
}

#modal_logout img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.875vw;
  height: 1.875vw;
}

.modal_log_btns {
  justify-content: center;
}

.modal_log_btns p {
  color: var(--color-6);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

p.noitems {
  color: var(--color-6);
  font-size: 0.625vw;
  font-weight: 500;
  width: 4.947916666666666vw;
  margin-right: 1.5625vw;
}

p.noitems span {
  font-weight: 700;
}

#select-items {
  width: 8.90625vw;
  border: 0px;
  background: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.625vw;
  font-weight: 500;
  border: none;
  margin-right: 1.9270833333333335vw;
}

.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}

.buscador {
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}

.buscador input {
  width: 15.312500000000002vw;
  border: none;
  height: 1.5625vw;
  color: #8d8d8d;
}

.icon_buscar img {
  width: 0.8854166666666666vw;
  height: 0.8854166666666666vw;
  padding-top: 0.3vw;
  margin-right: 0.5vw;
}

.btn_añadir {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 15.416666666666668vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}

.btn_añadir img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;

  padding-top: 0.6vw;
}

.btn_añadir p {
  width: 12vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1vw;
}

.select-container {
  z-index: 2;
}

/** estilos scroll bar */
.tbl_item::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tbl_item::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-button:increment,
.tbl_item::-webkit-scrollbar-button {
  display: none;
}

.tbl_item::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-thumb {
  background-color: #797979;
  /*border-radius: 1.0416666666666665VW;*/
  border: 0.10416666666666667vw solid #f1f2f3;
}

.tbl_item::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.boxform {
  display: grid;
  margin-right: 4.375vw;
  margin-bottom: 0.8854166666666666vw;
}

p.btnRegresar {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  padding: 0.7vw 0.7vw;
  text-align: center;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  margin-right: 2vw;
}

button.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}

.btn_añadir2 {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 11.1vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  margin-bottom: 2vw;
}

.btn_añadir2 img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 1.5vw;
  padding-top: 0.6vw;
}

.btn_añadir2 p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1vw;
}

.btn_añadir2 p span {
  font-weight: 700;
}

#uploadimg button {
  border: none !important;
  background: var(--color-5);
}

input[type="file"] {
  display: none;
}

.filelabel {
  padding: 0.8vw 0vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
  cursor: pointer;
}

.filelabelname {
  padding: 0.8vw 1vw;
  border-left: none;
  width: auto;
  cursor: pointer;

  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
}

p.snu {
  color: var(--color-4);
  text-align: center;
  font-size: 0.7vw;
}

.subtitle {
  color: var(--color-4);
  margin: 0px;
  font-size: 0.8333333333333334vw;
}

.subtitle2 {
  color: var(--color-4);
  font-weight: 600;
  font-size: 1.3020833333333335vw;
  margin: 0vw;
}

p.btnopciones {
  cursor: pointer;
  border: none;
  background: var(--color-3);
  color: var(--color-5);
  width: 10vw;
  text-align: center;
  transition: all 300ms;
  margin-right: 0.1vw;

  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.5vw;
  padding-top: 0.5vw;
}

.btnsgrp p:first-child {
  border-radius: 1.5vw 0vw 0vw 1.5vw;
}

.btnsgrp p:last-child {
  border-radius: 0vw 1.5vw 1.5vw 0vw;
}

p.btnopciones:hover {
  background: var(--color-2);
}

/** border-radius: 1.5vw; */

/*select */

.select-container {
  margin-right: 1.9270833333333335vw;
}

#select-items {
  width: 8.90625vw;
  height: 1.563vw;
  margin-top: 0.2vw;
  border: 0px;
  background-color: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  border: none;
  padding-left: 0.938vw;
  display: flex;
  align-items: center;
  background-image: url("/assets/flecha_select.png");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8.6vw;
}

input#select-ch,
input#select-ch2,
input#select-ch3,
input#select-ch4,
input#select-ch5,
input#select-ch6,
input#select-ch7 {
  display: none;
}

.options-cont {
  display: none;
}
.ajus-qull{
  width: 50vw !important;
}
.ajus-qull2{
  width: 24vw !important;
  margin-left: 1vw;
}
#select-ch:checked~.options-cont,
#select-ch2:checked~.options-cont,
#select-ch3:checked~.options-cont,
#select-ch4:checked~.options-cont,
#select-ch5:checked~.options-cont,
#select-ch6:checked~.options-cont,
#select-ch7:checked~.options-cont {
  display: flex;
  position: absolute;
  padding: 0.573vw 3.79vw;
  flex-direction: column;
  background-color: white;
  width: 7.22vw;
  border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}

#select-items.rounded {
  border-radius: 0.5vw 0.5vw 0 0;
}

.option-btn {
  cursor: pointer;
  font-size: 0.625vw;
  line-height: 1.25vw;
}

.option-btn:hover {
  background: gainsboro;
}

.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}

@media (min-width:768px) {

  body {
    margin: 0;
    font-size: 0.677vw;
    font-weight: 400;
    line-height: .677vw;
  }

  /***slides */
  .slide-item {
    height: 41.979vw;
  }

  .slide-item.mobile {
    display: none;
  }

  .slide-item.pc {
    display: flex;
  }

  .slide-cont {
    padding: 0 0 6vw 7vw;
  }

  .slide-cont a {
    padding: 1.042vw 2.604vw;
    border: 0.2vw solid white;
    font-size: 1.042vw;
    transition: .5s;
    line-height: 4vw;
  }

  /**fin slides */
  /*** validacione */

  .val_no {
    font-size: 1.942vw;
    line-height: 2.342vw;
    text-align: center;
  }

  /***fin validaciones */
  /**blog */
  .items-c {
    padding-top: 4.831vw;
    width: 67vw;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
  }

  .post-c img.post-img {
    width: 19.896vw;
    height: 16.250vw;
  }

  .post-c {
    width: 19.896vw;
    margin: 0 1vw;
    height: 31.25VW;
    display: flex;
    flex-direction: column;
  }

  .post-c a {
    display: block;
  }

  .itm {
    margin: auto 0 4.831vw 0;
  }

  /*.itm:nth-child(3n) {
    margin: auto 0 4.831vw 1vw;
}*/
  .post-c .info-c p {
    font-size: 0.677vw;
  }

  .post-c .info-c p.title {
    font-size: 0.938vw;
  }

  .post-c .info-c p.date {
    padding-top: 0.3vw;
  }

  .post-c .info-c {
    padding: 1.042vw 1.563vw 5.246vw;
  }

  .post-c a.btn {
    margin: auto auto 1.042vw 1.563vw;
    display: flex;
    width: fit-content;
  }

  .post-c a.btn p {
    padding: 0.469vw 1.042vw;
    border: 0.142vw solid black;
    margin: 0;
    font-family: var(--RedHat);
    font-weight: 700;
    color: black;
    transition: .5s;
  }

  /**fin blog */
}
</style>
